"use client";

import ValidatePath from "../lib/pathHelpers";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Authenticate from "../components/Authenticate";
import { FaCircleUser } from "react-icons/fa6";
import { SessionContext } from "../app/hooks/useSession";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { Breadcrumbs, BreadcrumbElementType } from "./Breadcrumbs";
import { FaQuestionCircle, FaSignOutAlt } from "react-icons/fa";

export default function AppNavigation({ path }: { path?: string }) {
  const {
    user,
    isLoading: isLoadingSession,
    signOut,
  } = useContext(SessionContext);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  // if path(-override) is provided, skip ValidatePath() which requires <Suspense/>
  const {
    pathname,
    isPathGames,
    isPathGame,
    isPathReport,
    isPathMerge,
    isPathValidate,
    isPathTeamSettings,
  } = path
    ? {
        pathname: path,
        isPathGames: false,
        isPathGame: false,
        isPathReport: false,
        isPathMerge: false,
        isPathValidate: false,
        isPathTeamSettings: false,
      }
    : ValidatePath();
  const router = useRouter();

  const onSignOut = () => {
    signOut();
    router.push(window.location.pathname);
    router.refresh();
  };

  let breadcrumbs: BreadcrumbElementType[] = [{ label: "Home", href: "/" }];
  if (
    isLoadingSession === "Session Ready" ||
    isLoadingSession == "Session Teams Ready"
  ) {
    if (
      isPathGames ||
      isPathGame ||
      isPathReport ||
      isPathMerge ||
      isPathValidate ||
      isPathTeamSettings
    ) {
      breadcrumbs.push({
        label: "Team",
        href: pathname.match(/\/t\/\d+/g)?.[0],
      });

      if (isPathGame) {
        breadcrumbs.push({ label: "Game" });
      } else if (
        isPathReport ||
        isPathMerge ||
        isPathValidate ||
        isPathTeamSettings
      ) {
        breadcrumbs.push({
          label: isPathReport
            ? "Report"
            : isPathMerge
            ? "Merge"
            : isPathValidate
            ? "Validate"
            : "Manage",
        });
      }
    }
  }

  // team name available: sessionTeams?.[pathname.match(/\/t\/(\d+)\/?.*$/)?.[1]]?.name
  return (
    <>
      <Navbar className="py-0" style={{ height: 55 }}>
        <Container fluid className="px-0">
          <Row className="w-100 mx-0">
            <Col xs={6} className="px-0 d-flex align-items-center">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Col>
            {isLoadingSession === "Session Ready" ||
            isLoadingSession == "Session Teams Ready" ? (
              <Col xs={6} className="d-flex justify-content-end px-0">
                {user !== null ? (
                  <Nav>
                    <NavDropdown
                      className="d-flex align-items-center justify-content-end"
                      align="end"
                      title={
                        user?.user_metadata?.hasOwnProperty("avatar_url") ? (
                          <>
                            <span className="text-secondary">
                              {user?.email?.match(/^([^@]*)@/)[1]}
                            </span>
                            &nbsp;
                            <Image
                              src={user?.user_metadata?.avatar_url}
                              referrerPolicy="no-referrer"
                              alt="User"
                              width={30}
                              height={30}
                              className="shadow-sm border"
                              style={{
                                verticalAlign: "middle",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        ) : (
                          <FaCircleUser className="text-secondary" />
                        )
                      }>
                      {user !== null ? (
                        <>
                          <NavDropdown.Item
                            onClick={() => onSignOut()}
                            className="d-flex py-0 align-items-center">
                            <FaSignOutAlt className="me-1 text-muted" />
                            Sign Out
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                        </>
                      ) : null}
                      <NavDropdown.Item
                        href="/help/"
                        className="d-flex py-0 align-items-center">
                        <FaQuestionCircle className="me-1 text-muted" />
                        Help
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : (
                  <FaCircleUser
                    className="fs-5 text-secondary"
                    onClick={() => setShowAuthModal(true)}
                  />
                )}
              </Col>
            ) : null}
          </Row>
        </Container>
      </Navbar>
      <Authenticate
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}
