"use client";

import Alert from "react-bootstrap/Alert";
import { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Authenticate from "../../components/Authenticate";
import Teams from "../../components/Teams";
import FuegoStatsTitle from "../../components/FuegoStatsTitle";
import {
  colorFuegoStatsBrown,
  colorFuegoStatsOrange,
  colorFuegoStatsYellow,
  colorMidGray,
  colorRedDark,
} from "../../lib/colors";
import { SessionContext } from "../hooks/useSession";
import AppNavigation from "../../components/AppNavigation";
import {
  FaArrowRight,
  FaChartLine,
  FaCircleExclamation,
  FaHeart,
  FaPlus,
} from "react-icons/fa6";
import { FaGlobeAmericas } from "react-icons/fa";
import { IconFrisbee } from "../../components/icons";

export default function Home() {
  const [showAuthModal, setShowAuthModal] = useState<boolean>();
  const { user, isLoading, sessionTeams } = useContext(SessionContext);
  const userIsUnauthenticated = user === null && isLoading === "Session Ready";
  const userReady = user !== null && isLoading === "Session Teams Ready";
  const hasTeams = sessionTeams && Object.keys(sessionTeams).length > 0;
  const isMounting =
    !userIsUnauthenticated &&
    isLoading !== "Session Teams Ready" &&
    sessionTeams === null;

  return (
    <>
      <AppNavigation path="/" />
      <FuegoStatsTitle includeLogo={true} isLoading={isMounting} />
      {isMounting ? null : userIsUnauthenticated || (userReady && !hasTeams) ? (
        <>
          <p className="text-center mt-4">
            <span
              className="fw-bold me-1"
              style={{ color: colorFuegoStatsOrange }}>
              No stream?
            </span>
            <span className="fw-bold" style={{ color: colorFuegoStatsBrown }}>
              No problem.
            </span>
          </p>
          <p className="text-center mb-1">
            <strong>Fuego Stats</strong> is a mobile-friendly gameflow
            statistics app for Ultimate focused on whole-team analytics. Stats
            entered by one user are rendered in real-time for coaches, players,
            and spectators at the field... and around the globe.
          </p>
          <HomeIconRow />
        </>
      ) : null}
      {userReady && !hasTeams ? (
        <Alert className="w-100 my-4 text-center border-2" variant="warning">
          <h5 className="mb-2">
            <FaCircleExclamation className="text-warning me-1" />
            Have a Team?
          </h5>
          <p className="my-1">
            <strong className="me-1">
              Your profile, <u>{user?.email}</u>, is not linked to any Fuego
              Stats teams.
            </strong>
            Either use your team&rsquo;s direct-link url or ask your team admin
            to add you to a team.
          </p>
        </Alert>
      ) : null}
      {userIsUnauthenticated ? (
        <div
          className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-4"
          style={{ marginBottom: "3em" }}>
          <div className="col-12 col-md-6 mb-2 mb-md-0 me-md-2">
            <Button
              onClick={() => setShowAuthModal(true)}
              variant="primary"
              className="w-100">
              Sign In
            </Button>
          </div>
          <div className="col-12 col-md-6">
            <Button href="/help/" variant="outline-secondary" className="w-100">
              Help
            </Button>
          </div>
        </div>
      ) : null}
      <Teams teams={sessionTeams} isLoading={hasTeams && !userReady} />
      <Authenticate
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}

const HomeIconRow = () => (
  <div className="text-center mt-3 mb-4">
    <IconFrisbee
      style={{
        color: colorFuegoStatsYellow,
        fontSize: "1.5em",
      }}
    />
    <FaPlus
      className="mx-2 opacity-50"
      style={{ color: colorMidGray, fontSize: "0.7em" }}
    />
    <FaChartLine style={{ color: colorFuegoStatsOrange, fontSize: "1.5em" }} />
    <FaPlus
      className="mx-2 opacity-50"
      style={{ color: colorMidGray, fontSize: "0.7em" }}
    />
    <FaGlobeAmericas
      style={{ color: colorFuegoStatsBrown, fontSize: "1.5em" }}
    />
    <FaArrowRight
      className="mx-2 opacity-50"
      style={{ color: colorMidGray, fontSize: "0.7em" }}
    />
    <FaHeart
      style={{ color: colorRedDark, fontSize: "1.5em" }}
      className="animation-beat"
    />
  </div>
);
